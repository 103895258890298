import React from 'react';
import '../App.css';
import './HeroSection.css';
import SketchComponent from './SketchComponent';


function HeroSection() {
  return (
    <div className='hero-container'>
      <div className='sketch'>
        <SketchComponent/>
      </div>
      <div className='header-container'>
        <h1>Driving Force<br></br>For Innovation</h1>
         <p>Vectorfields leverages cutting edge technologies and <br>
         </br>methods to bring innovation to you and your customers.</p>
      </div>
    </div>
  );
}

export default HeroSection;
