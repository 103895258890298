// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary: #fff;
}

.btn {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
}

.btn--primary {
    background-color: #5705FF;
    color: #fff;
    /*border: 1px solid var(--primary);*/
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 15px 32px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 24px;
    font-size: 16px
}

.btn--large {
    padding: 15px 32px;
    font-size: 16px
}

.btn--medium:hover, .btn--large:hover {
    background: #7530ff;
    color: #fff;
    transition: 250ms;
}`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,oCAAoC;AACxC;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,kBAAkB;IAClB,gCAAgC;IAChC,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB;AACJ;;AAEA;IACI,kBAAkB;IAClB;AACJ;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[":root {\n    --primary: #fff;\n}\n\n.btn {\n    padding: 10px 20px;\n    border-radius: 20px;\n    border: none;\n    outline: none;\n    cursor: pointer;\n}\n\n.btn--primary {\n    background-color: #5705FF;\n    color: #fff;\n    /*border: 1px solid var(--primary);*/\n}\n\n.btn--outline {\n    background-color: transparent;\n    color: #fff;\n    padding: 15px 32px;\n    border: 1px solid var(--primary);\n    transition: all 0.3s ease-out;\n}\n\n.btn--medium {\n    padding: 8px 24px;\n    font-size: 16px\n}\n\n.btn--large {\n    padding: 15px 32px;\n    font-size: 16px\n}\n\n.btn--medium:hover, .btn--large:hover {\n    background: #7530ff;\n    color: #fff;\n    transition: 250ms;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
