import React, { useRef } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Footer from './components/Footer';

function App() {
  const footerRef = useRef(null);

  function scrollToFooter() {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    else console.log("Error: Not current");
  }

  return (
    <>
    <Router>
    <Navbar scrollToFooter={scrollToFooter} />
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/services' />
        <Route path='/products' />
      </Routes>
      <Footer forwardRef={footerRef} />
    </Router>
    </>
  );
}

export default App;
