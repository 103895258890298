import React, { useRef, useState} from 'react';
import './Footer.css';
import { Button } from './Button.js';


function Footer({ forwardRef }) {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

        async function handleSubmit(event) {
          event.preventDefault();
          setIsLoading(true);

          // Print the email input to the console
          console.log("Form input (email):", email);
      
          // GraphQL Mutation for formData JSON field
          const ADD_EMAIL_MUTATION = `
          mutation createSubmission($formData: Json!, $formId: ID!) {
            createSubmission(data: {formData: $formData, form: {connect: {id: $formId}}}) {
                id
            }
        }
    `;
      
          try {
              const response = await fetch('https://api-ap-northeast-1.hygraph.com/v2/clmh15uac0yip01td5ocn9obt/master', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2OTQ2MDY0NDMsImF1ZCI6WyJodHRwczovL2FwaS1hcC1ub3J0aGVhc3QtMS5oeWdyYXBoLmNvbS92Mi9jbG1oMTV1YWMweWlwMDF0ZDVvY245b2J0L21hc3RlciIsIm1hbmFnZW1lbnQtbmV4dC5ncmFwaGNtcy5jb20iXSwiaXNzIjoiaHR0cHM6Ly9tYW5hZ2VtZW50LmdyYXBoY21zLmNvbS8iLCJzdWIiOiJmZWQ0NDljYy1jNmQ0LTRlODEtYjY0OC1mMGQ2Zjc3NjM0MjMiLCJqdGkiOiJjbG1ob3g4NGUwZGxqMDF1ZjBrb2Zkenk5In0.NFseXy-oktY_P7tXsjzrGlALXlePOuPYkYVc-3HmFB1k6J56Fbw9we31MMoan0gj89_U-COYlIWq9tO5QkF9g0DxyXnFdzwyarEppNqMRlAp9gue6BAnFIj9lC5q1aESJwxYGrfX0OLkRcv4Ye0JOGHu88XhNf_JMxQUSyT1cBOxg_6cK9IofoDleKZXNtRVD-KhuOLgsjWew9UvptRj9piKhl8it1XVhD1nQvEoGhJbQ7KYEUKJwDr1Ja4rvuHgK1r-h7X6V_E4HzRc9TtOzZtQ-gtWh7f1lwoMbWcP_p7JUxLZ4T2z0nALGkprXkU7Nmidy8mAtwRIeut96vw0z8L-6hSOkUWup_2Wf4R5nA51n0ekz2JAs8rMqPdRReNyXuQwkUzGutRnacL-yxK8EiQo0X5rHLK9_Nq3ZinzvYg9VgHmoulf4vptynXW6MpworV6NsQ8uMrEuQNQnesyMk_WgQSg35-QEglf3rMqLirEJbetzZwFWLL9tSbRmFUYLvydj26ErL3ESgh-x_wHix9RDA7eZ9Aj7mgkNDDoWBHtKoKfL7WGxbuAUInnFuRxWWfp3mVah4sTZULtpzh84GoDq_t9NTfs1C0jVGRULVaLKuoWLsTp-CFItaVFJvUcPIWE14m0_zspUpDPRhG5iGN3AkeqCq0LWcBCVFJLtkI' // Use the Permanent Auth Token for authentication
                    },
                    body: JSON.stringify({
                        query: ADD_EMAIL_MUTATION,
                        variables: { 
                            formData: {
                                FormInput: email
                            },
                            formId: 'clmhm97rqrci60a15j4cfbqxb'
                        },
                    }),
                });
      
              const data = await response.json();

              console.log("Server Response:", data);

              // Assuming data is the parsed JSON response
            if (data && data.data && data.data.createSubmission && data.data.createSubmission.id) {
              // Success case: We found the expected structure for a successful submission.
              alert('Email submitted successfully!');
            } else if (data && data.errors && Array.isArray(data.errors)) {
              // Error case: We found an errors array in the response.
              const errorMessages = data.errors.map(error => error.message).join(', ');
              alert(`There was an error submitting your email: ${errorMessages}`);
            } else {
             // General case: The response doesn't match any known structures.
              alert('An unknown error occurred while submitting your email.');
            }
          } catch (error) {
              console.error('Error details:', error.message);
              alert('There was an error submitting your email.');
          } finally {
              setIsLoading(false);
          }
      }
      

    return (
        <div className='footer-container' ref={forwardRef}>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    Join the mail list to receive updates on vectorfields.
                </p>
                <div className='input-areas'>
                    <form onSubmit={handleSubmit}>
                        <input
                            className='footer-input'
                            type='email'
                            placeholder='Your Email'
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                        <Button type="submit" disabled={isLoading}>Subscribe</Button>
                    </form>
                </div>
            </section>
            <small className='website-rights'>vectorfields © 2023 All rights reserved.</small>
        </div>
    );
}

export default Footer;