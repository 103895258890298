import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../logo.png";
import { useState, useEffect } from 'react';
import { Button } from './Button';
import './Navbar.css';

function Navbar( {scrollToFooter} ) {
  const navStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '24px',
    position: 'fixed',
    width: '100%',
    zIndex: 9999,
    boxShadow: '0px 4px 1px -3px rgba(16, 0, 57, 0.05)',
  };

  // const menuStyle = {
  //   listStyle: 'none',
  //   display: 'flex',
  //   gap: '28px',
  //   margin: '7px',
  // };

  // const menuItemStyle = {
  //   fontFamily: 'Open Sans',
  //   fontSize: '16px',
  //   color: 'black',
  //   textDecoration: 'none',
  // };

  const [click, setClick] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const handleClick = () => setClick(!click);
  const [button, setButton] = useState (true)
  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //       setButton(false);
  //   } else {
  //       setButton(true);
  //   }
  //   }

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
      if(window.innerWidth > 960) {
        setClick(false);
      }
    };

    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  return (
    <nav style={navStyle}>
      <Link to="/" className='navbar-logo'>
        <img src={logo} alt="vectorfields" style={{height: '28px', width: 'auto'}} />
      </Link>
      {/* <div className='menu-icon' onClick={handleClick} style={screenWidth > 960 ? { display: 'none' } : {}}>
        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      <ul style={click || screenWidth > 960 ? menuStyle : { display: 'none' }}>
        <Link style={menuItemStyle} to="/about" onClick={handleClick}>About</Link>
        <Link style={menuItemStyle} to="/chat" onClick={handleClick}>Chat</Link>
        <Link style={menuItemStyle} to="/latest-thinking" onClick={handleClick}>Latest Thinking</Link>
        <Link style={menuItemStyle} to="/contact" onClick={handleClick}>Contact</Link>
      </ul> */}
      {button && <Button buttonstyle='btn--outline' onClick={scrollToFooter}>Subscribe</
      Button>}
    </nav>
  );
};

export default Navbar;
