import React, { useRef, useEffect } from 'react';
import p5 from 'p5';

const SketchComponent = () => {
    const sketchRef = useRef();

    useEffect(() => {
        new p5(Sketch, sketchRef.current);
    }, []);

    const Sketch = (p) => {
        let locs = [];
    
        p.setup = () => {
            p.createCanvas(p.windowWidth, p.windowHeight);
            let res = 29;
            let countX = Math.ceil(p.width / res) + 1;
            let countY = Math.ceil(p.height / res) + 1;
    
            for (let j = 0; j < countY; j++) {
                for (let i = 0; i < countX; i++) {
                    locs.push(new p5.Vector(res * i, res * j));
                }
            }
    
            p.noFill();
            p.stroke(87, 5, 255);
            p.strokeWeight(2);
        };
    
        p.draw = () => {
            p.background(255, 255, 255);
            for (let i = locs.length - 1; i >= 0; i--) {
                let h = calcVec(locs[i].x - p.mouseX, locs[i].y - p.mouseY);
                p.line(
                    locs[i].x + 2,
                    locs[i].y + 2,
                    locs[i].x + 10 * h.heading(),
                    locs[i].y + 10 * h.heading()
                );
            }
        };
    
        function calcVec(x, y) {
            return new p5.Vector(x - y, -y - x);
        }
    }

    return (
        <div ref={sketchRef}>
        </div>
    );
    
}

export default SketchComponent;
